<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Booking Details</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<button class="btn btn-light mb-3 btn-sm" @click="goBack()">
				Go back 
			</button>
			<div class="d-flex content-wrapper">
				<div
					v-if="fetchingBooking"
					class="
            d-flex
            flex-column
            justify-content-center
            align-items-center
            w-100
          "
					>
					<div class="spinner-border" role="status"></div>
					<p class="text-center w-100">Loading details...</p>
				</div>
				<template v-else>
					<div class="w-100" v-if="booking">
						<div class="row">
							<div class="col-12 col-lg-5">
								<div class="card">
									<div class="card-body">
										<div>
											<h4 class="card-title">Trip Information</h4>
											<div class="border-bottom py-5">
												<div class="trip-info">
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0"
															>Ticket Type</span
															>
														<span class="trip-info__text mb-0">{{
															booking.recurring ? 'Subscription' : 'Single'
														}}</span>
													</div>
												</div>

												<div class="trip-info mt-3">
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0"
															>Ticket Number</span
															>
														<span class="trip-info__text mb-0">{{
															booking.bus_pass
														}}</span>
													</div>
												</div>

												<div class="trip-info mt-3">
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0">Trip days</span>
														<span class="trip-info__text mb-0">
                              {{
															tripDays
														}}
                            </span>
													</div>
												</div>

												<div
													class="trip-info mt-3"
													v-if="booking.userRoute.recurring"
													>
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0">Validity</span>
														<span class="trip-info__text mb-0"
															>{{
																booking.userRoute.start_date | date('MM-dd-yy')
															}}
															to
															{{
																booking.userRoute.end_date | date('MM-dd-yy')
															}}</span
															>
													</div>
												</div>

												<div class="trip-info mt-3">
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0"
															>Route Code</span
															>
														<span class="trip-info__text mb-0">{{
															booking.route.route_code
														}}</span>
													</div>
												</div>
												<div class="trip-info mt-3">
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0"
															>Trip Amount</span
															>
														<span class="trip-info__text mb-0">{{
															booking.userRoute.cost
														}}</span>
													</div>
												</div>
											</div>
											<div class="border-bottom py-5">
												<div class="trip-info d-flex mt-3">
													<div class="col-1">
														<svg-template code="pickup-sm" />
													</div>
													<div class="col-11 d-flex justify-content-between">
														<div class="d-flex flex-column">
															<p class="trip-info__title mb-0">Pick-up</p>
															<p class="trip-info__text mb-0">
																{{ booking?.userRoute?.origin_label }}
															</p>
														</div>
														<div class="d-flex flex-column align-items-end">
															<p class="trip-info__text mb-0">
																{{
																	booking.userRoute.start_date
																		| date('MM-dd-yy')
																}}
															</p>
															<p class="trip-info__text mb-0 text-capitalize">
																{{ booking?.userRoute?.itinerary?.trip_time }}
															</p>
														</div>
													</div>
												</div>
												<div class="trip-info d-flex mt-3">
													<div class="col-1">
														<svg-template code="destination-sm" />
													</div>
													<div class="col-11 d-flex justify-content-between">
														<div class="d-flex flex-column">
															<p class="trip-info__title mb-0">Drop off</p>
															<p class="trip-info__text mb-0">
																{{ booking?.userRoute?.destination_label }}
															</p>
														</div>
														<div class="d-flex flex-column align-items-end">
															<p class="trip-info__text mb-0">
																{{
																	booking?.userRoute?.start_date
																		| date('MM-dd-yy')
																}}
															</p>
															<p class="trip-info__text mb-0 text-uppercase">
																{{ arrivalTimeFormatted }}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div>
											<div class="border-bottom py-5" v-if="vehicleData">
												<div class="trip-info">
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0">Vehicle</span>
														<span class="trip-info__text mb-0">{{
															vehicleName
														}}</span>
													</div>
												</div>
												<div class="trip-info mt-3">
													<div class="col d-flex justify-content-between">
														<span class="trip-info__title mb-0"
															>Plate Number</span
															>
														<span class="trip-info__text mb-0">{{
															vehicleData?.registration_number || 'N/A'
														}}</span>
													</div>
												</div>
											</div>
											<div class="py-5" v-if="driverData">
												<div class="trip-info">
													<div class="col d-flex justify-content-between">
														<div class="d-flex align-items-center">
															<div class="mr-2">
																<user-avatar
																	v-if="driverData.avatar"
																	:user="driverData"
																	/>
																<img
																	v-else
																	:src="require('@/assets/img/user_avatar.svg')"
																	/>
															</div>
															<div>
																<p class="trip-info__text mb-0">Driver</p>
																<p
																	v-if="driverData"
																	class="trip-info__link-text mb-0"
																	@click="viewDriver"
																	>
																	{{
																		driverData?.fname + ' ' + driverData?.lname
																	}}
																</p>
																<span v-else class="text-muted"
																	>No driver available</span
																	>
															</div>
														</div>
													</div>
												</div>
												<div class="d-flex justify-content-center py-5">
													<button
														class="btn btn-dark col-10 btnblack"
														type="button"
														id="refundCustomerButton"
														aria-expanded="false"
														@click.prevent="checkRefund()"
														v-if="shouldShowRefundButton && userHasPermission('users009')"
														>
														Refund
													</button>
												</div>
											</div>
											<div>
												<button
													class="btn btn-primary w-100"
													@click="showCancelBookingModal = true"
													v-if="shouldShowCancelButton && userHasPermission('users012')"
													>
													Cancel Booking
												</button>
											</div>
										</div>
									</div>
								</div>
								<cancel-user-booking-modal
									:booking="booking"
									:show="showCancelBookingModal"
									@close="showCancelBookingModal = false"
									@trip-cancelled="refreshView($event)"
									>
								</cancel-user-booking-modal>
							</div>
							<div class="col-12 col-lg-7">
								<div class="map-container">
									<div style="height: 400px; width: 100%">
										<google-map
											:options="mapOptions"
											id="map-preview"
											ref="tripMap"
											:zoom="10"
											>
											<google-map-marker
												:icon="pickupMarker"
												:position="pickupPoint"
												:key="`preview-pickup-${booking.route.id}`"
												/>

											<google-map-marker
												:icon="destMarker"
												:position="destinationPoint"
												:key="`preview-destination-${booking.route.id}`"
												/>
											<google-map-polyline
												:path="[...polyLinePath]"
												:strokeWeight="3"
												strokeColor="#000000"
												></google-map-polyline>
										</google-map>
									</div>
								</div>
							</div>
						</div>
					</div>
					<p v-else class="text-center w-100">Data Unavailable</p>
				</template>
			</div>
		</div>
		<template>
			<refund-customer-modal
				:show="showRefundCustomerModal"
				:booking="booking"
				:scheduleId="scheduleId"
				@close="showRefundCustomerModal = false"
				@showRefund="checkRefund"
				></refund-customer-modal>
		</template>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import SvgTemplate from '@/components/core/svg-template'
import pickup_marker from '@/assets/img/pickup_marker.svg'
import destination_marker from '@/assets/img/dest_marker.svg'
import moment from 'moment'
import UserAvatar from '@/components/core/UserAvatar'
import CancelUserBookingModal from '@/views/Users/components/CancelUserBookingModal'
import RefundCustomerModal from '@/components/modals/RefundCustomerModal.vue'
import Swal from 'sweetalert2'

export default {
  name: 'BookingTicket',
  components: {
    CancelUserBookingModal,
    UserAvatar,
    SvgTemplate,
    RefundCustomerModal
  },
  props: {
    bookingId: [Number, String]
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => (vm.prevRoute = from))
  },
  data () {
    return {
      prevRoute: null,
      luggageValue: 1,
      showBusDetails: false,
      fetchingBooking: true,
      booking: {},
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      },
      mapInstance: null,
      showCancelBookingModal: false,
      bookingEndDate: null,
      currentDate: null,
      showRefundCustomerModal: false,
      scheduleId: parseInt(this.$route.params.scheduleId)
    }
  },
  created () {
    if (this.bookingId) {
      this.fetchBooking()
    } else {
      this.fetchingBooking = false
    }
  },
  computed: {
    destMarker () {
      return destination_marker
    },
    pickupMarker () {
      return pickup_marker
    },
    driverData () {
      return this.booking && this.booking.driver ? this.booking.driver : null
    },

    vehicleData () {
      return this.booking && this.booking.vehicle ? this.booking.vehicle : null
    },

    shouldShowCancelButton () {
      return this.booking.status === 'ACTIVE'
    },
    shouldShowRefundButton () {
      return this.booking.status === 'COMPLETED' && this.booking.is_refunded === 0
    },

    vehicleName () {
      if (this.booking && this.vehicleData) {
        const { brand, name, type } = this.vehicleData
        return `${brand} ${name} - ${type}`
      }
      return 'N/A'
    },
    arrivalTimeFormatted () {
      if (this.booking && this.booking.route && this.booking.itinerary) {
        const [route_duration, departure_time, time_of_day] = [
          this.booking.route.duration,
          this.booking.itinerary.departure_time,
          this.booking.itinerary.time_of_day
        ]
        const durationParts = route_duration?.split?.(' ') ?? ''
        let duration = parseInt(durationParts[0])
        if (durationParts[1] === 'hour') {
          duration = duration * 60
        }
        return moment(`${departure_time} ${time_of_day}`, 'h:mm A')
          .add(duration, 'm')
          .format('h:mm a')
      } else {
        return ''
      }
    },
    polyLinePath () {
      const newGeometryArray = [
        this.booking?.userRoute?.destinationRouteBusStop?.geometry,
        this.booking?.userRoute?.pickupRouteBusStop?.geometry
      ]
      return newGeometryArray?.map((coord) => {
        return {
          lng: coord.x,
          lat: coord.y
        }
      })
    },
    destinationPoint () {
      const { x, y } =
        this.booking?.destinationRouteBusStop?.geometry ||
        this.booking?.userRoute?.destinationRouteBusStop?.geometry
      return {
        lng: x,
        lat: y
      }
    },
    pickupPoint () {
      const { x, y } =
        this.booking?.pickupRouteBusStop?.geometry ||
        this.booking?.userRoute?.pickupRouteBusStop?.geometry
      return {
        lng: x,
        lat: y
      }
    },
    tripDays () {
      if (this.booking && this.booking.userRoute.selected_days) {
        const uniqueDays = new Set(this.booking.userRoute.selected_days || [])
        return this.booking.userRoute.selected_days.length
          ? Array.from(uniqueDays)
            .map((obj) => obj.slice(0, 3))
            .join(', ')
          : ''
      }
      return ''
    }
  },
  methods: {
    setDateCheckParams (booking) {
      const endOfDay = (date) => {
        const eod = new Date(date)
        if (eod && eod !== 'Invalid Date') {
          const newDate = new Date(date.split(' ')[0]).setHours(23, 59, 59)
          return newDate
        } else {
          return null
        }
      }
      if (booking?.end_date) {
        this.bookingEndDate = endOfDay(booking?.end_date)
      }
      this.currentDate = new Date(
        booking?.start_date?.split?.(' ')[0]
      ).getTime()
    },
    fetchBooking () {
      this.fetchingBooking = true
      this.axios
        .get(`v1/user-route-schedules/${this.$route.params.scheduleId}`)
        .then((response) => {
          this.booking = response.data
          this.setDateCheckParams(this.booking)
        })
        .catch((e) => {
          (e)
        })
        .finally(() => {
          this.fetchingBooking = false
          this.setMapPosition()
        })
    },
    goBack () {
      if (this.prevRoute) {
        this.$router.push({
          path: this.prevRoute.path
        })
      } else {
        this.$router.push({
          name: 'ShowUser',
          params: { userId: this.booking.user_id }
        })
      }
    },
    setMapPosition () {
      if (this.booking) {
        this.$nextTick(() => {
          if (this.$refs.tripMap) {
            this.$refs.tripMap.$_getMap().then((v) => {
              this.mapInstance = v
              this.mapInstance.panTo(this.pickupPoint)
            })
          }
        })
      }
    },
    viewDriver () {
      this.$router.push({
        name: 'ShowDriver',
        params: { driverId: this.driverData.id }
      })
    },
    cancelBooking () {
      this.showCancelBookingModal = true
    },
    refreshView (completelyCancelled) {
      if (completelyCancelled) {
        this.fetchBooking()
      } else {
        this.goBack()
      }
    },
    checkRefund () {
      if (this.booking?.cost === 0) {
        this.$swal({
          icon: 'error',
          title: 'You cannot perform a refund when the trip cost is N0',
          showConfirmButton: true,
          showCancelButton: false
        })
      } else {
        this.showRefundCustomerModal = !this.showRefundCustomerModal
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$neutral-400: #acafae;
$neutral-500: #8d918f;
$neutral-800: #313533;
$neutral-600: #737876;
$label-color: #131615;

//$gray-3: ;
.small {
  font-size: 0.7rem;
  max-width: 7rem;
}

.page-container {
  background: #ffffff;
  padding: 1.8rem;

  .page-header-wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .header-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: -0.02em;
      /* Gray/Gray3 */
      color: #444854;
    }

    .input-group {
      background: #ffffff;
      border: 0.5px solid #c0c0c0;
      border-radius: 8px;
      width: 550px;
      max-width: 100%;
    }
  }
}

.content-wrapper {
  position: relative;

  .trip-info {
    &__title {
      font-weight: 500;
      color: $label-color;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    &__text {
      color: $neutral-500;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    &__link-text {
      color: $label-color;
      text-decoration: underline;
      font-size: 0.875rem;
      line-height: 1.25rem;
      cursor: pointer;

      &:hover {
        color: #00e677;
      }
    }

    // &__icon {

    // }
  }

  .map-container {
    border: 3px solid #fff;
    border-radius: 1rem;
    width: 100%;
    min-height: 400px;

    div {
      border-radius: inherit;
    }

    ::v-deep .map-view {
      border-radius: inherit;
    }
  }
}

.btnblack {
  background-color: #000005;
}
</style>
